import { isMobile } from '../utils/device';
import { handleDrEditionVideo } from './dr-edition-video';
import { trackTeaser } from '../front-tracking.js';
import Lazyload from '../utils/Lazyload';
import { getConsentedToAllSync } from '@vgno/utils';
import { getEnvironmentId } from '../tracking/pulse';

const BASE_URL = 'https://dr-edition-server.inventory.schibsted.io';

const excludedItems = [];

/**
 * @param {string} url
 * @param {string} as
 */
function preload(url, as) {
    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = url;
    link.as = as;
    document.head.appendChild(link);
}

function getPathnameFromParsedUrl(url) {
    try {
        const website = new URL(url);

        return website.pathname;
    } catch (error) {
        console.log('Error parsing redirect URL', url);

        return '';
    }
}

function appendTrackingDataScript(pulseData, editionName, container) {
    const title = document.createElement('div');
    title.innerHTML = pulseData.title;

    const trackingDataScript = document.createElement('script');
    trackingDataScript.type = 'application/json';
    trackingDataScript.textContent = JSON.stringify({
        articleId: getPathnameFromParsedUrl(pulseData.url),
        // For legacy tracking
        drFrontId: 'df-' + pulseData.creativeId,
        section: editionName,
        teaserText: title.textContent,

        // Defaults
        skin: 'default',
        size: 1,
        brand: 'vg',
        skinIcon: null,
    });
    trackingDataScript.className = 'tracking-data';
    container.appendChild(trackingDataScript);
}

async function fetchDrEditionItem(
    editionName,
    excludedItems,
    SPiD,
    editionDataUrl,
    envid,
) {
    const { userId } = SPiD;
    const url = `${BASE_URL}/editions?editionName=vg-${editionName}&excludedItems=${JSON.stringify(
        excludedItems,
    )}&spid=${userId}&envid=${envid}&urlData=${editionDataUrl}`;

    const response = await fetch(url);
    const data = await response.json();

    if (data.css) {
        preload(data.css, 'style');
    }

    return data;
}

/**
 * @param {string} htmlContent
 * @returns {string}
 */
function removeTrackingPixel(htmlContent) {
    const pattern = /<img [^>]*class="tracking-pixel"[^>]*>/g;
    return htmlContent.replace(pattern, '');
}

function renderItem(drEditionContainer, item) {
    const isMobileDevice = isMobile();

    const consentedToAll = getConsentedToAllSync();

    const mobileHTML = consentedToAll
        ? item.mobileHTML
        : removeTrackingPixel(item.mobileHTML);
    const desktopHTML = consentedToAll
        ? item.desktopHTML
        : removeTrackingPixel(item.desktopHTML);

    if (isMobileDevice) {
        drEditionContainer.innerHTML = mobileHTML;
    } else {
        drEditionContainer.innerHTML = desktopHTML;
    }

    trackTeaser(drEditionContainer);
}

function appendDataAttributesForContainer(element, pulseData) {
    element.dataset.creativeId = pulseData.creativeId;
    element.dataset.itemId = pulseData.itemId;
    element.dataset.editionId = pulseData.editionId;
    element.dataset.algorithm = pulseData.algorithm ?? 'sov';
}

function getEditionDataFromUrl() {
    const url = window.location.href;
    const urlObj = new URL(url);
    return urlObj.searchParams.get('dre_override_inv_code') || '';
}

async function fetchAndHandleDrEditionItem(front) {
    try {
        const drEditionArticle = front.querySelector('.articles');
        const drEditionDataContainer = front.querySelector('.container');
        let SPiD = {};
        try {
            SPiD = await window.SPiD_Identity.hasSession();
        } catch (error) {
            // silent catch
        }

        const envid = await getEnvironmentId();

        const editionDataUrl = getEditionDataFromUrl();

        const item = await fetchDrEditionItem(
            front?.id,
            excludedItems,
            SPiD,
            editionDataUrl,
            envid,
        );

        if (!item?.itemId) {
            front.classList.add('partnerstudio-empty');

            return;
        }

        excludedItems.push(item.itemId);
        renderItem(drEditionArticle, item);
        appendDataAttributesForContainer(
            drEditionDataContainer,
            item.pulseData,
        );

        if (
            item?.videoConfiguration?.id ||
            item?.videoConfiguration?.preview?.url
        ) {
            handleDrEditionVideo(item.videoConfiguration);
        }

        appendTrackingDataScript(item.pulseData, front?.id, drEditionArticle);
        // For faster tapping in Safari. See: https://www.reddit.com/r/webdev/comments/18zjara/i_frigging_love_ios_safari/
        drEditionDataContainer
            .querySelector('a')
            .addEventListener('pointerup', () => null);
    } catch (error) {
        console.log('Error fetching dr. edition item');
    }
}

export default async () => {
    const drEditionElements = $$('.partnerstudio-front');

    const observer = new Lazyload(drEditionElements, {
        rootMargin: '400% 0px',
    });
    observer.observe(({ target }) => fetchAndHandleDrEditionItem(target));
};
